.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0 auto;
  // background-image: url("https://res-front.pumpsoul.com/prod-erasa/image/background-icon.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  .collectContainer {
    // position: absolute;
    // top: 10px;
    right: 200px;
    width: 200px;
    height: 50px;
    background: red;
    border-radius: 10px;
  }

  .header,
  .footer {
    // position: relative;
    width: 100%;
    background: var(--foreground-rgb);
  }

  .header {
    z-index: 998;
  }

  .main {
    // background: var(--foreground-rgb);
    width: 100% !important;

    // margin-top: 0px;
    // background-color: var(--foreground-rgb);
    background-size: 100% auto;
    background-repeat: no-repeat;
    // background-image: url("https://res-front.pumpsoul.com/prod-cobra/image/background-icon.png");
    // background-image: url('/images/layouts/starBgk.webp');

    &.mobile {
      margin-top: var(--pietra-global-mobile-header-heigth);
    }
  }
  .leftFolder {
    // float: left;
  }
}

@media (max-width: 900px) and (min-width: 576px) {
  .layout {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 575px) {
  .layout {
    justify-content: flex-start;
  }
}

